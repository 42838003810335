import axios from "axios";
import settings from "@/settings";

const API_URL = settings.API_URL

export default {

    /* MAIN */
    LOGIN(username, password) {
        return axios.post(API_URL + 'bridge/login.php', {
            partner: settings.PARTNER,
            username: username,
            password: password
        })
    },
    LOGOUT() {
        return axios.post(API_URL + 'bridge/logout.php', {
            token: sessionStorage.token
        })
    },
    /* BULLETINS */
    GET_MAKERS() {
        return axios.post(API_URL + 'tecdoc/listMakers.php', {
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    GET_MODELS(maker) {
        return axios.post(API_URL + 'tecdoc/listModels.php', {
            maker: maker,
            token: sessionStorage.token
        })
    },
    GET_VEHICLES(modelID) {
        return axios.post(API_URL + 'tecdoc/listVehicles.php', {
            model: modelID,
            token: sessionStorage.token
        })
    },
    GET_HISTORY() {
        return axios.post(API_URL + 'bridge/listHistory.php', {
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    SELECT_VEHICLE(vehicleID) {
        axios.post(API_URL + 'bridge/selectVehicle.php', {
            vehicleID: vehicleID,
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    COUNT_BULLETINS(vehicleID) {
        return axios.post(API_URL + 'bridge/countBulletins.php', {
            vehicleID: vehicleID,
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    GET_BULLETINS(vehicleID) {
        return axios.post(API_URL + 'bridge/listBulletins.php', {
            vehicleID: vehicleID,
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    GET_NEW_SEARCH(maker, str) {
        return axios.post(API_URL + 'bridge/searchDTC.php', {
            maker: maker,
            search: str,
            username: sessionStorage.username,
            token: sessionStorage.token
        })
    },
    SUBMIT_FEEDBACK(vehicleID, bulletinID, comment) {
        axios.post(API_URL + 'bridge/feedback.php', {
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token,
            vehicleID: parseInt(vehicleID),
            bulletinID: parseInt(bulletinID),
            comment: comment
        })
    },

    /* HAYNES PRO */
    GET_HAYNES_LIST(vehicle, language) {
        return axios.post(API_URL + 'haynes/list.php', {
            vrid: sessionStorage.haynes_vrid,
            vehicle: vehicle,
            language: language
        })
    },

    GET_HAYNES_DETAIL(type, id, language) {
        return axios.post(API_URL + 'haynes/detail.php', {
            vrid: sessionStorage.haynes_vrid,
            type: type,
            id: id,
            language: language
        })
    },

    /* FORMACIÓN */
    GET_LESSONS_FILES(dir) {
        return axios.post(API_URL + 'bridge/scandir.php', {
            dir: dir,
            username: sessionStorage.username,
            token: sessionStorage.token,
        })
    },



}
