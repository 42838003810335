<template>
  <div>
    <div class="background"></div>
    <div class="background2"></div>
    <div class="language-selector">
      <language-selector></language-selector>
    </div>
    <v-container fluid style="height:100vh">

      <v-row class="fill-height align-items-center justify-content-center">
        <div class="col" style="max-width:700px;">
          <div class="row">
            <div class="col-12 col-sm-6 bg-white h-auto">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-auto px-3 py-4 text-center">
                  <hr>

                  <!-- SELECT PARTNER LOGO -->
                  <img v-if="partner === 'next'" class="px-3" src="../assets/logo-negro-naranja-vertical.png" alt="">
                  <img v-if="partner === 'andel'" class="px-3" src="../assets/partners/andel.png" alt="">
                  <img v-if="partner === 'bestdrive'" class="px-3" src="../assets/partners/bestdrive.png" alt="">
                  <img v-if="partner === 'cga2'" class="px-3" src="../assets/partners/cga.png" alt="">
                  <img v-if="partner === 'optimacar'" class="px-3" src="../assets/partners/optimacar.png" alt="">
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 h-auto py-4" style="background-color: #222">

              <div class="container text-white">
                <div class="text-center">
                  <h2>{{ TEXT('log_in') }}</h2>
                </div>
                <hr>
                <div class="alert alert-danger py-1" v-if="showerror">{{TEXT('login_error')}}</div> <!-- TODO Translate -->
                <form>
                  <div class="form-group">
                    <label>{{ TEXT('user') }}</label>
                    <input type="text" class="form-control" v-model="username" :placeholder="TEXT('user')">
                  </div>
                  <div class="form-group">
                    <label>{{ TEXT('password') }}</label>
                    <input type="password" class="form-control" v-model="password" :placeholder="TEXT('password')">
                  </div>
                  <hr>
                  <div class="text-right">
                    <button type="submit" class="btn btn-light font-weight-bold" @click.prevent="signIn"><i
                        class="fas fa-sign-in-alt"></i> {{ TEXT('enter').toUpperCase() }}
                    </button>
                  </div>
                </form>

              </div>

            </div>
            <div v-if="theme.powered" class="mt-2 col-12 text-center text-white d-block d-lg-none">
              <span class="ml-4">Powered By</span>
              <img class="ml-3" src="../assets/logo-next.webp" alt="">
            </div>
          </div>
        </div>
      </v-row>

    </v-container>
    <div v-if="theme.powered" class="d-none d-lg-block white--text" style="left:10px; bottom:35px; height:30px; position: fixed;">
      <span>Powered By</span><br>
      <img src="../assets/logo-next.webp" alt="">
    </div>
  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";
import LanguageSelector from "@/components/LanguageSelector.vue";
import api from "@/api";

export default {
  name: "login-view",
  components: {LanguageSelector},
  data() {
    return {
      username: '',
      password: '',
      showerror: 0
    }
  },
  computed: {
    ...mapState([
      'partner',
      'theme'
    ]),
    ...mapGetters([
      'TEXT',
    ])
  },
  methods: {
    signIn() {
      api.LOGIN(this.username, this.password)
          .then(response => {
        if (response.data['login']['OK']) {
          sessionStorage.username = response.data['login']['USUARIO']['NOMBRE_TALLER']
          sessionStorage.token = response.data['login']['TOKEN']
          sessionStorage.haynes_vrid = response.data.haynes.HEYNES_SESSION.vrid
          this.$emit('login', sessionStorage.username);
        } else {
          this.showerror = 1;
        }
      });
    }
  },
  mounted() {
    this.$emit('logout');
  }
}
</script>

<style scoped>
.background {
  background: url('../assets/fondo-next2.png') center center no-repeat;
  background-size: cover;
  filter: opacity(20%);
  width: 100%;
  z-index: -1;
  min-height: 100%;
  position: fixed;
}

.background2 {
  background-color: #000000;
  width: 100%;
  z-index: -2;
  min-height: 100%;
  position: fixed;
}

img {
  max-width: 100%;
}

.language-selector {
  position: fixed;
  right: 0px;
  z-index: 1000;
  font-size: 18pt;
}
</style>