<template>
  <div class="container-fluid">
    <div class="d-inline-flex">
      <img :src="'https://assets.infotechnic.es/logos/'+vehicle.maker.toLowerCase()+'.png'" alt="" class="img-fluid mr-2"
           style="height: 50px">
      <h1>{{ vehicle.maker }} {{ vehicle.model }}</h1>
    </div>
    <hr class="mt-1">
    <div class="container">
      <table class="table table-sm table-dark table-striped table-hover">
        <thead class="bg-primary">
        <tr>
          <th>{{ TEXT('type') }}</th>
          <th>{{ TEXT('engine') }}</th>
          <th>{{ TEXT('power') }}</th>
          <th>{{ TEXT('displacement') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="vehicle in vehicles" @click="setVehicle(vehicle)" :key="vehicle.id">
          <td>{{ vehicle.DESCRIPCION }}</td>
          <td>{{ vehicle.MOTORES }}</td>
          <td>{{ vehicle.KW }} <small>KW ({{ vehicle.CV }} CV)</small></td>
          <td>{{ vehicle.CC }} cc</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";

export default {
  name: "VehicleSelector",
  data() {
    return {
      vehicles: [],
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle'
    ]),
  },
  methods: {
    listVehicles() {
      api.GET_VEHICLES(this.vehicle.modelID)
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.vehicles = response.data.list
            }
          })
    },
    setVehicle(selected) {
      let vehicle = this.vehicle
      vehicle.motor = selected.MOTORES
      vehicle.vehicleID = selected.ID
      vehicle.extcode = selected.ID
      this.$store.commit('UPDATE_VEHICLE', vehicle)
      this.$store.commit('CHANGE_TSB_VIEW', 3)
    },
  },
  created() {
    this.listVehicles();
    if (!this.vehicle.modelID) {
      this.$store.commit('CHANGE_TSB_VIEW', 0)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style scoped>
table td {
  cursor: pointer;
}
</style>
