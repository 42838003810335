<template>
  <v-card dark width="350">
    <v-list dense class="pa-3">
      <v-subheader>{{TEXT('latest_10_vehicles')}}</v-subheader>
      <v-list-item
          link
          @click="selectVehicle(vehicle)"
          v-for="vehicle in history"
          :key="vehicle['ID']">
        <v-list-item-content>
          <v-list-item-title>
            {{ vehicle['MARCA'] }}  <small>{{ vehicle['MODELO'] }}</small>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ vehicle['MOTORIZACION'] }} ({{vehicle['MOTORES']}}) [{{ vehicle['DESDE'] }} / {{ vehicle['HASTA'] }}]
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HistorySelector",
  props: ['history'],
  computed: {
    ...mapGetters(['TEXT']),
  },
  methods: {
    selectVehicle(selected) {
      let vehicle = {}
      vehicle.maker = selected['MARCA']
      vehicle.makerID = selected['ID']
      vehicle.model = selected['MODELO']
      vehicle.modelID = selected['ID']
      vehicle.motor = selected['MOTORES']
      vehicle.vehicleID = selected['ID']
      vehicle.extcode = selected['ID']
      this.$store.commit('UPDATE_VEHICLE', vehicle)
      this.$store.commit('CHANGE_TSB_VIEW', 3)
    }
  }
}
</script>
