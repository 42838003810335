export default {
    VERSION: '0.3.1',
    API_URL: 'https://api.infotechnic.es/',

    /* SELECT PARTNER */
    // PARTNER: 'next'
    // PARTNER: 'andel'
    // PARTNER: 'bestdrive'
    // PARTNER: 'cga2'
    PARTNER: 'optimacar'
}

/* THEME SELECTOR
*  settings.js
*  theme.scss
*  router.js
*  App.vue (logo)
* */