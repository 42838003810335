import Vue from 'vue'
import Vuex from 'vuex'
import settings from "@/settings";
import themes from "@/themes";
import dictionary from "@/dictionary";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        logged: 1,
        menu: false,
        partner: settings.PARTNER,
        theme: themes[settings.PARTNER],
        tsb_view: 0,
        vehicle: {},
        category: null,
        categoryName: "",
        language: 'es'
    },
    getters: {
        TEXT: (state) => (id) => {
            return dictionary[id][state.language]
        }
    },
    mutations: {
        UPDATE_LOGGED(state, newState) {
            state.logged = newState
        },
        UPDATE_MENU(state, value) {
            state.menu = value
        },
        UPDATE_VEHICLE(state, vehicle) {
            state.vehicle = vehicle
        },
        CHANGE_TSB_VIEW(state, view) {
            state.tsb_view = view
        },
        CHANGE_CATEGORY(state, category) {
            state.category = category[0]
            state.categoryName = category[1]
        },
        CHANGE_LANGUAGE(state, language) {
            state.language = language
        },
    },
    actions: {},
    modules: {}
})
