import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '@/views/LoginView.vue'
import BulletinsView from '@/views/BulletinsView.vue'
import SystemLessonsView from '@/views/SystemLessonsView.vue'
import VehicleLessonsView from "@/views/VehicleLessonsView.vue";
import ManagementLessonsView from "@/views/ManagementLessonsView.vue";
import ProcedureView from '@/views/ProcedureView.vue'
import PartnersView from "@/views/PartnersView.vue";
import VideoView from "@/views/VideoView.vue";
import AutoLogin from "@/views/AutoLogin.vue";
import PortugalLessonsView from "@/views/PortugalLessonsView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/bulletins',
        name: 'bulletins',
        component: BulletinsView
    },
    {
        path: '/procedure',
        name: 'procedure',
        component: ProcedureView
    },
    {
        path: '/academy/system',
        name: 'system-lessons',
        component: SystemLessonsView
    },
    {
        path: '/academy/vehicle',
        name: 'vehicle-lessons',
        component: VehicleLessonsView
    },
    {
        path: '/academy/management',
        name: 'management-lessons',
        component: ManagementLessonsView
    },
    {
        path: '/academy/pt',
        name: 'portugal-lessons',
        component: PortugalLessonsView
    },
    {
        path: '/partners',
        name: 'partners',
        component: PartnersView
    },
    {
        path: '/video',
        name: 'video',
        component: VideoView
    },
    {
        path: '/autologin/:user/:token',
        name: 'autologin',
        component: AutoLogin
    },
    {path: '*', redirect: '/'}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
