export default {
    next: {
        primary: '#ed6b00',
        dark: true,
        video: true,
        powered: true,
    },
    andel: {
        primary: '#e7521f',
        dark: false,
        video: false,
        powered: true,
    },
    bestdrive: {
        primary: '#eca02d',
        dark: true,
        video: false,
        powered: true,
    },
    cga2: {
        primary: '#004f84',
        dark: false,
        video: false,
        powered: true,
    },
    optimacar: {
        primary: '#ffc000',
        dark: true,
        video: false,
        powered: false,
    }
}